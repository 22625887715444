<template>
  <div>
    <b-card-code
      :title="title"
    >
      <b-card-body>
        <validation-observer ref="formValidation">
          <b-form>
            <b-row
              class="align-content-center mx-auto"
            >
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <h3 v-if="id !== undefined">
                  <strong>Ticket: #{{ ticket_id }}</strong>
                </h3>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group
                  label="Usuario creador"
                  label-for="created_by"
                >
                  <b-form-input
                    id="created_by"
                    v-model="created_by"
                    readonly="readonly"
                    placeholder="Usuario creador"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-content-center mx-auto">
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="Cliente"
                  label-for="customer_id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cliente"
                    rules="required"
                  >
                    <b-input-group>
                      <v-select
                        v-model="customer_id"
                        :dir="'ltr'"
                        label="full_name"
                        :class="`b-select ${accessPermissions('customers.store') ? 'calc-w' : 'w-100'}`"
                        :state="errors.length > 0 ? false : ''"
                        :options="rowsCustomers"
                        @input="onChangeCustomer"
                      >
                        <span slot="no-options">No hay data para cargar</span>
                      </v-select>
                      <b-input-group-append
                        v-if="accessPermissions('customers.store')"
                      >
                        <b-button
                          v-b-modal.modal-customers
                          variant="success"
                          class="btn-icon w-40px"
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="Sucursal"
                  label-for="branch_id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sucursal"
                    rules="required"
                  >
                    <v-select
                      v-model="branch_id"
                      :dir="'ltr'"
                      label="full_name"
                      class="b-select w-100"
                      :state="errors.length > 0 ? false : ''"
                      :options="rowsCustomersBranches"
                      @input="onChangeBranch"
                    >
                      <span slot="no-options">No hay data para cargar</span>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="Encargados"
                  label-for="notifications"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="notificaciones"
                    rules="required"
                  >
                    <b-input-group>
                      <v-select
                        v-model="notifications"
                        multiple
                        :dir="'ltr'"
                        label="fullname"
                        :class="`b-select ${accessPermissions('customers.contact.store') ? 'calc-w' : 'w-100'}`"
                        :state="errors.length > 0 ? false : null"
                        :options="rowsNotifications"
                      >
                        <span slot="no-options">No hay data para cargar</span>
                      </v-select>
                      <b-input-group-append
                        v-if="accessPermissions('customers.contact.store')"
                      >
                        <b-button
                          v-b-modal.modal-customers-notify
                          :disabled="isDisabled"
                          variant="success"
                          class="btn-icon w-40px"
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="Contactos"
                  label-for="contacts"
                >
                  <b-input-group>
                    <v-select
                      v-model="contacts"
                      multiple
                      :dir="'ltr'"
                      label="fullname"
                      :class="`b-select ${accessPermissions('customers.contact.store') ? 'calc-w' : 'w-100'}`"
                      :options="rowsContacts"
                    >
                      <span slot="no-options">No hay data para cargar</span>
                    </v-select>
                    <b-input-group-append
                      v-if="accessPermissions('customers.contact.store')"
                    >
                      <b-button
                        v-b-modal.modal-customers-contact
                        :disabled="isDisabled"
                        variant="success"
                        class="btn-icon w-40px"
                      >
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="Categoría"
                  label-for="category_id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="categoría"
                    rules="required"
                  >
                    <v-select
                      v-model="category_id"
                      :dir="'ltr'"
                      label="name"
                      class="b-select w-100"
                      :state="errors.length > 0 ? false : null"
                      :options="rowsCategories"
                    >
                      <span slot="no-options">No hay data para cargar</span>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="Estado"
                  label-for="status"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="estado"
                    rules="required"
                  >
                    <b-form-select
                      v-model="status"
                      class="b-select w-100"
                      :options="option"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="Asignado a"
                  label-for="assigned_id"
                >
                  <v-select
                    v-model="assigned_id"
                    :dir="'ltr'"
                    label="full_name"
                    class="b-select"
                    :options="rowsAssigneds"
                  >
                    <span slot="no-options">No hay data para cargar</span>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
              >
                <b-form-group
                  label="Asunto"
                  label-for="subject"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="asunto"
                    rules="required"
                  >
                    <b-form-input
                      id="subject"
                      v-model="subject"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Asunto"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="id === undefined"
                sm="12"
              >
                <b-form-group
                  label="Descripción"
                  label-for="description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="descripción"
                    rules="required"
                  >
                    <quill-editor
                      id="description"
                      v-model="description"
                      :options="editorOption"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="id === undefined"
                sm="12"
              >
                <b-form-group
                  label="Los archivos adjuntos"
                  label-for="file"
                >
                  <b-form-file
                    v-model="file"
                    multiple
                    placeholder="Elija un archivo o suéltelo aquí..."
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-content-center mx-auto">
              <b-col
                sm="12"
                md="12"
              >
                <b-button
                  v-if="id === undefined ? accessPermissions('ticket.store') : accessPermissions('ticket.update')"
                  class="float-right text-right"
                  variant="primary"
                  type="submit"
                  @click.prevent="onHandleValidationForm"
                >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                  />
                  Guardar
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  class="float-right mr-1"
                  :to="{ name: 'tickets' }"
                >
                  Cancelar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card-code>

    <DescriptionComponent
      v-if="id !== undefined && this.accessPermissions('ticket.show')"
      :id="id"
    />

    <b-modal
      v-if="accessPermissions('customers.store')"
      id="modal-customers"
      ref="customers-modal"
      centered
      hide-backdrop
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      size="lg"
      title="Agregar un nuevo Cliente"
      @hidden="hideModal"
    >
      <b-card-body>
        <FormCreateClientComponent
          root="modal"
          endPoint="customers"
          @setHiddenModal="hideModal"
        />
      </b-card-body>
    </b-modal>
    <b-modal
      v-if="customer_id !== '' && accessPermissions('customers.contact.store')"
      id="modal-customers-notify"
      ref="customers-contact-modal"
      centered
      hide-backdrop
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      size="md"
      title="Agregar un nuevo Contacto de Notificación"
      @hidden="hideContactModal"
    >
      <b-card-body>
        <CustomersContactFormComponent
          :customerId="customer_id.id"
          :contactId="undefined"
          :chedkbox="true"
          @setHiddenModal="hideContactModal"
        />
      </b-card-body>
    </b-modal>
    <b-modal
      v-if="customer_id !== '' && accessPermissions('customers.contact.store')"
      id="modal-customers-contact"
      ref="customers-contact-modal"
      centered
      hide-backdrop
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      size="md"
      title="Agregar un nuevo Contacto"
      @hidden="hideContactModal"
    >
      <b-card-body>
        <CustomersContactFormComponent
          :customerId="customer_id.id"
          :contactId="undefined"
          :chedkbox="false"
          @setHiddenModal="hideContactModal"
        />
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BFormFile,
  BFormSelect,
  BModal,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import router from '@/router'
import FormCreateClientComponent from '@/pages/dashboard/customers/components/FormCreateComponent.vue'
import CustomersContactFormComponent from '@/pages/dashboard/customers/components/CustomersContactFormComponent.vue'
import DescriptionComponent from './DescriptionComponent.vue'
import CustomersService from '@/pages/dashboard/customers/services/CustomersService'
import CustomersContactService from '@/pages/dashboard/customers/services/CustomersContactService'
import CustomersBranchService from '@/pages/dashboard/customers/services/CustomersBranchService'
import AgentsServices from '@/pages/dashboard/settings/agents/services/AgentsServices'
import CategoriesServices from '@/pages/dashboard/settings/categories/services/CategoriesServices'
import TicketsService from '../services/TicketsService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'FormCreateComponent',
  components: {
    quillEditor,
    BCardCode,
    BCardBody,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BFormFile,
    BFormSelect,
    BModal,
    FormCreateClientComponent,
    CustomersContactFormComponent,
    DescriptionComponent,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      title: this.id === undefined ? 'Agregar un nuevo Ticket' : 'Actualizar Ticket',
      option: [
        { value: 'In Progress', text: 'En Progreso' },
        { value: 'On Hold', text: 'Pendiente' },
        { value: 'Closed', text: 'Cerrado' },
      ],
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: ['serif', 'monospace'] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
          ],
        },
        theme: 'snow',
        placeholder: 'Inserte texto aquí...',
      },
      isDisabled: true,
      ticket_id: '',
      created_by: '',
      customer_id: '',
      branch_id: '',
      notifications: '',
      contacts: '',
      category_id: '',
      assigned_id: '',
      status: '',
      subject: '',
      description: '',
      file: null,
      required,
      rowsCustomers: [],
      rowsCustomersBranches: [],
      rowsNotifications: [],
      rowsContacts: [],
      rowsCategories: [],
      rowsAssigneds: [],
    }
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.ticket_id = ''
      this.customer_id = ''
      this.branch_id = ''
      this.notifications = ''
      this.contacts = ''
      this.category_id = ''
      this.status = ''
      this.assigned_id = ''
      this.subject = ''
      this.description = ''
      this.file = null
      this.rowsCustomers = []
      this.rowsCustomersBranches = []
      this.rowsNotifications = []
      this.rowsContacts = []
      this.rowsCategories = []
      this.rowsAssigneds = []
      this.$refs.formValidation.reset()
    },
    hideModal() {
      this.onHandleCustomersList()
      this.$refs['customers-modal'].hide()
    },
    hideContactModal() {
      this.onHandleCustomersContactsList(this.branch_id.id)
      this.$refs['customers-contact-modal'].hide()
    },
    async onChangeCustomer(event) {
      this.branch_id = ''
      this.notifications = ''
      this.contacts = ''
      this.rowsCustomersBranches = []
      this.rowsNotifications = []
      this.rowsContacts = []

      if (this.customer_id.id === '') {
        this.isDisabled = true
      } else {
        this.isDisabled = false
        await this.onHandleCustomersBranchesList(event.id)
      }
    },
    async onChangeBranch(event) {
      this.notifications = ''
      this.contacts = ''
      this.rowsNotifications = []
      this.rowsContacts = []

      if (this.branch_id.id === '') {
        this.isDisabled = true
      } else {
        this.isDisabled = false
        await this.onHandleCustomersContactsList(event.id)
      }
    },
    async onHandleCustomersList() {
      await CustomersService.handleList().then(response => {
        this.rowsCustomers = response.data.data
      }).catch(error => this.errorResp(error))
    },
    async onHandleCustomersBranchesList(customerId) {
      await CustomersBranchService.handleList(customerId).then(response => {
        this.rowsCustomersBranches = response.data.data
      }).catch(error => this.errorResp(error))
    },
    async onHandleCustomersContactsList(branchId) {
      await CustomersContactService.handleListByBranches(branchId).then(response => {
        const notifications = []
        const contacts = []

        if (response.data.data.length > 0) {
          response.data.data.map(({ id, fullname, notify }) => {
            if (notify === 'Si') {
              notifications.push({ id: id, fullname: fullname })
            } else {
              contacts.push({ id: id, fullname: fullname })
            }
          })
        }

        this.rowsNotifications = notifications.length === 0 ? [] : notifications
        this.rowsContacts = contacts.length === 0 ? [] : contacts
        this.notifications = notifications.length === 0 ? '' : notifications
      }).catch(error => this.errorResp(error))
    },
    async onHandleAssignedList() {
      await AgentsServices.handleList().then(response => {
        const data = []

        if (response.data.data.length > 0) {
          response.data.data.map(({ id, profile }) => {
            data.push({ id: id, full_name: profile === null ? '' : profile.full_name })
          })
        }

        this.rowsAssigneds = data.length === 0 ? null : data
      }).catch(error => this.errorResp(error))
    },
    async onHandleCategoriesList() {
      await CategoriesServices.handleList().then(response => {
        this.rowsCategories = response.data.data
      }).catch(error => this.errorResp(error))
    },
    async onHandleEdit() {
      const storage = JSON.parse(localStorage.getItem('userData'))
      this.created_by = storage.profile === null ? '' : storage.profile.full_name

      await this.onHandleCustomersList()
      await this.onHandleCategoriesList()
      await this.onHandleAssignedList()

      if (this.id !== undefined && this.accessPermissions('ticket.show')) {
        await TicketsService.handleEdit(this.id).then(response => {
          let assigned = ''
          let customer = ''
          let branch = ''
          const notifications = []
          const contacts = []

          this.onHandleCustomersBranchesList(response.data.data.customer_id)

          if (response.data.data.customer_branch.length > 0) {
            this.onHandleCustomersContactsList(response.data.data.customer_branch[0].id)

            branch = {
              id: response.data.data.customer_branch[0].id,
              full_name: response.data.data.customer_branch[0].full_name,
            }

            if (response.data.data.contacts.length > 0) {
              response.data.data.contacts.map(({ contact_id, type, contact }) => {
                if (contact !== null) {
                  if (type === 'Notify') {
                    notifications.push({ id: contact_id, fullname: contact.fullname })
                  } else if (type === 'Contact') {
                    contacts.push({ id: contact_id, fullname: contact.fullname })
                  }
                }
              })
            }
          }

          if (response.data.data.customer !== null) {
            this.isDisabled = false
            customer = {
              id: response.data.data.customer_id,
              full_name: response.data.data.customer.full_name,
            }
          }

          if (response.data.data.assigned_id !== null) {
            assigned = {
              id: response.data.data.assigned.id,
              full_name: response.data.data.assigned.profile === null ? '' : response.data.data.assigned.profile.full_name,
            }
          }

          this.ticket_id = response.data.data.ticket_id === null ? '' : response.data.data.ticket_id
          this.created_by = response.data.data.created_name === null ? '' : response.data.data.created_name
          this.description = response.data.data.description === null ? '' : response.data.data.description
          this.customer_id = customer
          this.branch_id = branch
          this.notifications = notifications.length === 0 ? '' : notifications
          this.contacts = contacts.length === 0 ? null : contacts
          this.category_id = response.data.data.category_relations === null ? null : response.data.data.category_relations
          this.status = response.data.data.status === null ? '' : response.data.data.status
          this.subject = response.data.data.subject === null ? '' : response.data.data.subject
          this.assigned_id = assigned
          this.created_by = response.data.data.created_name
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const formData = new FormData()

          if (this.contacts && this.contacts.length > 0) {
            this.contacts.map(({ id }) => {
              formData.append('contacts[]', id)
            })
          }

          if (this.notifications && this.notifications.length > 0) {
            this.notifications.map(({ id }) => {
              formData.append('notifications[]', id)
            })
          }

          formData.append('customer_id', this.customer_id.id)
          formData.append('branches[]', this.branch_id.id)
          formData.append('category', this.category_id.id)
          formData.append('status', this.status)
          formData.append('assigned_id', !this.assigned_id || !this.assigned_id.id ? '' : this.assigned_id.id)
          formData.append('subject', this.subject)
          formData.append('description', this.description)

          if (this.id === undefined && this.accessPermissions('ticket.store')) {
            if (this.file !== null) {
              if (this.file.length > 0) {
                this.file.map(item => {
                  formData.append('attachments[]', item)
                })
              }
            }

            TicketsService.handleStore(formData).then(response => {
              if (response.data.data) {
                this.swal('El ticket ha sido registrado correctamente', 'success')
                this.clearForm()

                router.push({ name: 'tickets' })
              }
            }).catch(error => {
              const err = JSON.parse(error.request.response)
              const str = JSON.stringify(err.error)
              const query = str.indexOf('attachments')

              if (query === -1) {
                this.errorResp(error)
              } else {
                this.swal('Los archivos debe ser de tipo: doc, pdf, docx, zip, png, jpeg, jpg', 'error')
              }
            })
          } else if (this.id !== undefined && this.accessPermissions('ticket.update')) {
            TicketsService.handleUpdate(this.id, formData).then(response => {
              if (response.data.data) {
                this.swal('El ticket ha sido actualizado correctamente', 'success')
                this.clearForm()

                router.push({ name: 'tickets' })
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
.b-select {
  min-height: 36px !important;
  border-radius: 4px !important;
  width: auto;
}

.vs__selected-options {
  min-height: 30px !important;
}

.calc-w {
  width: calc(100% - 40px) !important;
}

.w-40px {
  width: 40px !important;
}
</style>
