<template>
  <b-row
    v-if="files.length > 0"
  >
    <b-col
      class="mt-2"
      sm="12"
    >
      <strong>Los archivos adjuntos</strong>
      <hr>
    </b-col>
    <b-col
      sm="12"
    >
      <ul class="list-group list-group-flush">
        <li
          v-for="({ url, value }, index) in files"
          :key="index"
        >
          <a
            :href="value"
            target="_blank"
          >
            Archivo {{ index + 1 }}
          </a>
        </li>
      </ul>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
} from 'bootstrap-vue'

export default {
  name: 'TicketFilesComponent',
  components: {
    BRow,
    BCol,
  },
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
