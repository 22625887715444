<template>
  <div>
    <div>
      <b-button
        v-if="id !== undefined && accessPermissions('ticket.resendticket')"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="float-right"
        @click="onHandleResendTicket"
      >
        Reenviar Ticket
      </b-button>
      <b-button
        v-if="id !== undefined && accessPermissions('ticket.reply')"
        v-b-modal.modal-ticket
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="float-right mr-1"
      >
        Responder
      </b-button>
    </div>
    <b-modal
      v-if="accessPermissions('ticket.reply')"
      id="modal-ticket"
      ref="ticket-modal"
      centered
      hide-backdrop
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      size="md"
      title="Agregar una nueva Respuesta"
      @hidden="hideModal"
    >
      <b-card-body>
        <validation-observer ref="formValidation">
          <b-form>
            <b-row>
              <b-col
                sm="12"
              >
                <b-form-group
                  label="Descripción"
                  label-for="descriptionModal"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="descripción"
                    rules="required"
                  >
                    <quill-editor
                      id="descriptionModal"
                      v-model="descriptionModal"
                      :options="editorOption"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
              >
                <b-form-group
                  label="Los archivos adjuntos"
                  label-for="filesModal"
                >
                  <b-form-file
                    v-model="filesModal"
                    multiple
                    placeholder="Elija un archivo o suéltelo aquí..."
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                class="mt-1 position-relative text-right align-content-end float-end"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="float-right"
                  type="submit"
                  @click.prevent="onHandleValidationForm"
                >
                  <feather-icon
                    icon="MailIcon"
                    class="mr-50"
                  />
                  Enviar
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  class="float-right mr-1"
                  @click="hideModal"
                >
                  Cerrar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>
import {quillEditor} from 'vue-quill-editor'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BForm,
  BFormFile,
} from 'bootstrap-vue'
import {required} from '@validations'
import TicketsService from '../services/TicketsService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'TicketButtonActionComponent',
  components: {
    quillEditor,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BForm,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{size: ['small', false, 'large', 'huge']}],
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            [{font: ['serif', 'monospace']}],
            [{color: []}, {background: []}],
            [{align: []}],
          ],
        },
        theme: 'snow',
        placeholder: 'Inserte texto aquí...',
      },
      descriptionModal: '',
      filesModal: null,
      required,
    }
  },
  created() {
    this.clearForm()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.descriptionModal = ''
      this.filesModal = null
    },
    hideModal() {
      this.clearForm()
      this.$refs['ticket-modal'].hide()
    },
    async onHandleResendTicket() {
      if (this.id !== undefined && this.accessPermissions('ticket.resendticket')) {
        await TicketsService.handleResend(this.id).then(response => {
          if (response.data.data) {
            this.swal('El ticket ha sido reenviado correctamente', 'success')
          }
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      if (this.id !== undefined && this.accessPermissions('ticket.reply')) {
        await this.$refs.formValidation.validate().then(success => {
          if (success) {
            const formData = new FormData()

            if (this.filesModal !== null) {
              if (this.filesModal.length > 0) {
                this.filesModal.map(item => {
                  formData.append('attachments[]', item)
                })
              }
            }

            formData.append('description', this.descriptionModal)

            TicketsService.handleReply(this.id, formData).then(response => {
              if (response.data.data) {
                this.swal('El ticket ha sido actualizado correctamente', 'success')
                this.$emit('setLoadEdit')
                this.hideModal()
                this.$refs.formValidation.reset()
              }
            }).catch(error => {
              const err = JSON.parse(error.request.response)
              const str = JSON.stringify(err.error)
              const query = str.indexOf('attachments')

              if (query === -1) {
                this.errorResp(error)
              } else {
                this.swal('Los archivos debe ser de tipo: doc, pdf, docx, zip, png, jpeg, jpg', 'error')
              }
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
