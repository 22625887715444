<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          md="11"
          sm="12"
        >
          <b-form-group
            label="Sucursal"
            label-for="branch_id"
          >
            <validation-provider
              #default="{ errors }"
              name="sucursal"
              rules="required"
            >
              <v-select
                id="branch_id"
                v-model="branch_id"
                :state="errors.length > 0 ? false:null"
                :dir="'ltr'"
                label="full_name"
                :options="rowsBranches"
              >
                <span slot="no-options">No hay data para cargar</span>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="11"
          sm="12"
        >
          <b-form-group
            label="Nombre completo"
            label-for="fullname"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre completo"
              rules="required"
            >
              <b-form-input
                id="fullname"
                v-model="fullname"
                :state="errors.length > 0 ? false:null"
                placeholder="Nombre completo"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="11"
          sm="12"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              :rules="notify ? 'required|email' : 'email'"
            >
              <b-form-input
                v-model="emailValue"
                :state="errors.length > 0 ? false:null"
                type="email"
                placeholder="Email"
              />
              <small class="text-danger">{{ notify ? errors[0] : null }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="11"
          sm="12"
        >
          <b-form-group
            label="Teléfono"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="phone"
              placeholder="Teléfono"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="11"
          sm="12"
        >
          <b-form-group
            label="Puesto"
            label-for="position"
          >
            <b-form-input
              id="position"
              v-model="position"
              placeholder="Puesto"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="11"
          sm="12"
        >
          <b-form-group
            label="Nota"
            label-for="note"
          >
            <b-form-textarea
              id="note"
              v-model="note"
              placeholder="Nota"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="11"
          sm="12"
        >
          <b-form-group
            label="Encargado"
            label-for="notify"
          >
            <b-form-checkbox
              v-model="notify"
              class="custom-control-primary"
            >
              Si
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          md="11"
          sm="12"
        >
          <b-button
            v-if="contactId === undefined ? accessPermissions('customers.contact.store') : accessPermissions('customers.contact.update')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="closeForm"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import CustomersContactService from '../services/CustomersContactService'
import CustomersBranchService from '../services/CustomersBranchService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'CustomersContactFormComponent',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
  },
  props: {
    customerId: {
      type: Number,
      required: true,
    },
    contactId: {
      type: Number,
      required: false,
    },
    chedkbox: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      fullname: '',
      emailValue: '',
      phone: '',
      position: '',
      note: '',
      notify: this.chedkbox,
      branch_id: '',
      rowsBranches: [],
      required,
      email,
    }
  },
  watch: {
    async customerId() {
      await this.onHandleBranchesList()
      await this.onHandleEdit(this.contactId)
    },
    async contactId() {
      await this.onHandleBranchesList()
      await this.onHandleEdit(this.contactId)
    },
  },
  async created() {
    await this.onHandleBranchesList()
    await this.onHandleEdit(this.contactId)
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.fullname = ''
      this.emailValue = ''
      this.phone = ''
      this.position = ''
      this.note = ''
      this.branch_id = ''
      this.notify = false
      this.rowsBranches = []
      this.$refs.formValidation.reset()
      this.$emit('setHiddenModal')
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleBranchesList() {
      await CustomersBranchService.handleList(this.customerId).then(({ data }) => {
        this.rowsBranches = data.data.length === 0 ? [] : data.data
      }).catch(error => this.errorResp(error))
    },
    async onHandleEdit(id) {
      if (this.customerId !== undefined && this.contactId !== undefined && this.accessPermissions('customers.contact.show')) {
        await CustomersContactService.handleEdit(id).then(({ data }) => {
          let branch = ''

          if (data.data.customer_branch_id !== null && data.data.customer_branch !== null) {
            branch = {
              id: data.data.customer_branch_id,
              full_name: data.data.customer_branch.full_name,
            }
          }

          this.fullname = data.data.fullname
          this.emailValue = data.data.email
          this.phone = data.data.phone
          this.position = data.data.position
          this.note = data.data.note
          this.branch_id = branch
          this.notify = data.data.notify === 'Si' ? true : false
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const param = {
            fullname: this.fullname,
            email: this.emailValue,
            phone: this.phone,
            position: this.position,
            note: this.note,
            notify: this.notify ? 'Si' : 'No',
            customer_id: this.customerId,
            customer_branch_id: this.branch_id.id,
          }

          if (this.customerId !== undefined && this.contactId === undefined && this.accessPermissions('customers.contact.store')) {
            CustomersContactService.handleStore(param).then(({ data }) => {
              if (data.data) {
                this.swal('El contacto ha sido registrado correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          }

          if (this.customerId !== undefined && this.contactId !== undefined && this.accessPermissions('customers.contact.update')) {
            CustomersContactService.handleUpdate(this.contactId, param).then(({ data }) => {
              if (data.data) {
                this.swal('El contacto ha sido actualizado correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
