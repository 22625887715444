<template>
  <div>
    <b-card-code
      title="Descripción"
    >
      <b-card-body>
        <b-row>
          <b-col
            sm="12"
          >
            <div v-html="descrip" />
          </b-col>
        </b-row>
        <FilesComponent
          :files="files"
        />
        <ButtonActionComponent
          v-if="id !== undefined && (accessPermissions('ticket.reply') || accessPermissions('ticket.resendticket'))"
          :id="id"
          @setLoadEdit="onHandleEdit"
        />
      </b-card-body>
    </b-card-code>
    <div
      v-if="conversions.length > 0"
    >
      <b-card-code
        v-for="({ sender, description, attachments, created_at }, index) in conversions"
        :key="index"
        :title="sender === 'user' ? customer : sender.full_name"
        :created_at="created_at"
      >
        <b-card-body>
          <b-row>
            <b-col
              sm="12"
            >
              <div v-html="description" />
            </b-col>
          </b-row>
          <FilesComponent
            :files="attachments"
          />
        </b-card-body>
      </b-card-code>
    </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BCardBody,
  BCol,
  BRow,
} from 'bootstrap-vue'
import ButtonActionComponent from './ButtonActionComponent.vue'
import FilesComponent from './FilesComponent.vue'
import TicketsService from '@/pages/dashboard/tickets/services/TicketsService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'TicketDescriptionComponent',
  components: {
    BCardCode,
    BCardBody,
    BRow,
    BCol,
    ButtonActionComponent,
    FilesComponent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      descrip: '',
      customer: '',
      files: [],
      conversions: [],
      created_by: '',
    }
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('ticket.show')) {
        await TicketsService.handleEdit(this.id).then(response => {
          const attachment = []
          const conversions = []

          if (response.data.data.attachments.length > 0) {
            response.data.data.attachments.map(item => {
              attachment.push(item)
            })
          }

          if (response.data.data.conversions.length > 0) {
            response.data.data.conversions.map(item => {
              conversions.push(item)
            })
          }

          this.created_by = response.data.data.created_name === null ? '' : response.data.data.created_name
          this.descrip = response.data.data.description === null ? '' : response.data.data.description
          this.customer = response.data.data.customer === null ? '' : response.data.data.customer.full_name
          this.files = attachment
          this.conversions = conversions
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
