<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nombre completo"
            label-for="full_name"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre completo"
              rules="required"
            >
              <b-form-input
                id="full_name"
                v-model="full_name"
                :state="errors.length > 0 ? false:null"
                placeholder="Nombre completo"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="endPoint === 'customers'"
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Identificación"
            label-for="dni"
          >
            <b-form-input
              id="dni"
              v-model="dni"
              placeholder="Identificación"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                v-model="emailValue"
                :state="errors.length > 0 ? false:null"
                type="email"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Tipo"
            label-for="type"
          >
            <v-select
              v-model="type"
              :dir="'ltr'"
              label="title"
              :options="option"
            >
              <span slot="no-options">No hay data para cargar</span>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="fields.length > 0"
        class="align-content-center mx-auto d-flex flex-column"
      >
        <b-col
          v-for="(input, index) in fields"
          :key="`fieldsInput-${index}`"
          lg="8"
          md="10"
          sm="12"
        >
          <b-row>
            <b-col
              lg="10"
              md="9"
              sm="9"
              cols="9"
              style="margin-bottom: 10px;"
            >
              <label>Teléfono {{index+1}}</label>
              <b-form-input
                type="text"
                :maxlength="20"
                v-model="input.phone"
                :mask="/[0-9()+]/"
                :placeholder="`Teléfono ${index+1}`"
              />
            </b-col>
            <b-col
              lg="2"
              md="2"
              sm="2"
              cols="2"
              class="text-right"
            >
              <div
                class="d-inline-block position-relative mt-2"
                style="top: 3px;"
              >
                <button
                  v-if="fields.length > 1"
                  class="btn btn-md btn-danger width-40px m-0 p-0 mr-1"
                  type="button"
                  @click="removeField(index)"
                >
                  <feather-icon
                    class="position-relative mx-auto text-center justify-content-center d-block"
                    icon="MinusIcon"
                  />
                </button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="align-content-center mx-auto d-flex flex-column mb-1">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class="btn btn-md btn-success width-40px m-0 p-0"
            type="button"
            @click="addField"
          >
            <feather-icon
              class="position-relative mx-auto text-center justify-content-center d-block"
              icon="PlusIcon"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          v-if="type.title === 'Persona Natural'"
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Celular"
            label-for="mobile"
          >
            <b-form-input
              type="text"
              :maxlength="20"
              v-model="mobile"
              :mask="/[0-9()+]/"
              placeholder="Celular"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Dirección"
            label-for="address"
          >
            <b-form-textarea
              id="address"
              v-model="address"
              placeholder="Dirección"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="endPoint === 'branches'"
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nota"
            label-for="note"
          >
            <b-form-textarea
              id="note"
              v-model="note"
              placeholder="Nota"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            v-if="id === undefined ? accessPermissions('customers.store') : accessPermissions('customers.update')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="closeForm"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormGroup,
  BForm,
  BFormInput,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import router from '@/router'
import CustomersService from '../services/CustomersService'
import CustomersBranchService from '../services/CustomersBranchService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'FormCreateComponent',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormGroup,
    BForm,
    BFormInput,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: false,
    },
    isActive: {
      type: Boolean,
      required: false,
    },
    root: {
      type: String,
      required: true,
    },
    endPoint: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      option: [
        { title: 'Persona Natural' },
        { title: 'Empresa' },
      ],
      fields: [{ phone: '' }],
      full_name: '',
      dni: '',
      emailValue: '',
      mobile: '',
      type: '',
      address: '',
      note: '',
      required,
      email,
    }
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
    customer() {
    },
    async isActive() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  mounted() {
    this.type = this.option[0]
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    addField() {
      this.fields.push({ phone: '' })
    },
    removeField(number) {
      this.fields.forEach((item, index, map) => {
        if (index === number) map.splice(index, 1)
      })
    },
    clearForm() {
      this.fields = [{ phone: '' }]
      this.full_name = ''
      this.dni = ''
      this.emailValue = ''
      this.mobile = ''
      this.address = ''
      this.note = ''
      this.type = this.option[0]
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      if (this.root === 'customers') {
        router.push({ name: 'customers' })
      } else {
        this.$emit('setHiddenModal')
      }
    },
    async onHandleEdit() {
      if (this.isActive && this.id !== undefined) {
        if (this.endPoint === 'customers' && this.accessPermissions('customers.show')) {
          await CustomersService.handleEdit(this.id).then(({ data }) => {
            this.full_name = data.data.full_name
            this.dni = data.data.dni
            this.emailValue = data.data.email
            this.mobile = data.data.mobile
            this.address = data.data.address
            this.note = data.data.note
            this.type = data.data.type === 'Persona Natural' ? this.option[0] : this.option[1]
            this.fields = (data.data.phone.length === 0 || data.data.phone === null) ? [{phone: ''}] : data.data.phone
          }).catch(error => this.errorResp(error))
        } else if (this.endPoint === 'branches' && this.accessPermissions('customer.branch.show')) {
          await CustomersBranchService.handleEdit(this.id).then(({ data }) => {
            this.full_name = data.data.full_name
            this.dni = data.data.dni
            this.emailValue = data.data.email
            this.mobile = data.data.mobile
            this.address = data.data.address
            this.note = data.data.note
            this.type = data.data.type === 'Persona Natural' ? this.option[0] : this.option[1]
            this.fields = (data.data.phone.length === 0 || data.data.phone === null) ? [{ phone: '' }] : data.data.phone
          }).catch(error => this.errorResp(error))
        }
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const param = {
            customer: this.customer === undefined ? null : this.customer,
            full_name: this.full_name,
            dni: this.dni,
            email: this.emailValue,
            type: this.type.title,
            phone: this.fields,
            mobile: this.mobile,
            address: this.address,
            note: this.note,
          }

          if (this.id === undefined) {
            if (this.endPoint === 'customers' && this.accessPermissions('customers.store')) {
              CustomersService.handleStore(param).then(({ data }) => {
                if (data.data) {
                  this.swal('El cliente ha sido registrado correctamente', 'success')
                  this.clearForm()

                  if (this.root === 'customers') {
                    router.push({ name: 'customers-edit', params: { id: data.data.id } })
                  } else {
                    this.$emit('setHiddenModal')
                  }
                }
              }).catch(error => this.errorResp(error))
            } else if (this.endPoint === 'branches' && this.accessPermissions('customer.branch.store')) {
              CustomersBranchService.handleStore(param).then(({ data }) => {
                if (data.data) {
                  this.swal('El cliente ha sido registrado correctamente', 'success')
                  this.clearForm()

                  if (this.root === 'customers') {
                    router.push({ name: 'customers-edit', params: { id: data.data.id } })
                  } else {
                    this.$emit('setHiddenModal')
                  }
                }
              }).catch(error => this.errorResp(error))
            }
          } else if (this.id !== undefined) {
            if (this.endPoint === 'customers' && this.accessPermissions('customers.update')) {
              CustomersService.handleUpdate(this.id, param).then(({ data }) => {
                if (data.data) {
                  this.swal('El cliente ha sido actualizado correctamente', 'success')
                  this.closeForm()
                }
              }).catch(error => this.errorResp(error))
            } else if (this.endPoint === 'branches' && this.accessPermissions('customer.branch.update')) {
              CustomersBranchService.handleUpdate(this.id, param).then(({ data }) => {
                if (data.data) {
                  this.swal('El cliente ha sido actualizado correctamente', 'success')
                  this.closeForm()
                }
              }).catch(error => this.errorResp(error))
            }
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.width-40px {
  border-radius: 100% !important;
  height: 40px !important;
  width: 40px !important;
  position: relative !important;
}
</style>
